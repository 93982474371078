import Request from '@/utils/request'

// 用户信息
export function getUserInfo() {
  return Request('get', '/apg/system/user/current/loginInfo')
}

//获取审批数量
export function getTaskCount(data) {
  return Request('post', '/apd/oa/approve/myTaskStatusCnt', data)
}

//获取审批列表
export function getMyTaskList(data) {
  return Request('post', '/apd/oa/approve/myApproveTasks', data)
}

//【审批】头部信息
export function getTaskHead(data) {
  return Request('post', '/apa/contract/new/approveDetailInfo', data)
}

//【请求】租赁价格表格
export function getContractRentPrice(data) {
  return Request('post', '/apa/contract/new/getContractRentPrice', data)
}

//【请求】相似房源表格
export function getContractLikeResource(data) {
  return Request('post', '/apa/contract/new/contractLikeResource', data)
}

//【审批】进度列表
export function getTaskList(data) {
  return Request('post', '/apd/oa/approve/new/approveExecutionInfo', data)
}

// 【审批】获取审批评论合并列表
export function getTaskCommentInfo(data) {
  return Request('post', '/apd/oa/approve/approveExecutionMergeComment', data)
}

//【审批】撤回请求
export function getRevocation(data) {
  return Request('post', '/apd/oa/approve/undo', data)
}

//【审批】拒绝请求
export function getRefuse(data) {
  return Request('post', '/apd/oa/approve/reject', data)
}

//【审批】同意请求
export function getAgree(data) {
  return Request('post', '/apd/oa/approve/accept', data)
}

//【审批】转交请求
export function getTransfer(data) {
  return Request('post', '/apd/oa/approve/transfer', data)
}

// 【审批评论】是否开放评论区
export function getCommentShow(data) {
  return Request('post', '/apg/comment/exist', data)
}

// 【审批评论】获取评论列表
export function getCommentList(data) {
  return Request('post', '/apg/comment/list', data)
}

// 【审批评论】发送评论
export function addComment(data) {
  return Request('post', '/apg/comment/create', data)
}

// 【审批评论】撤回评论
export function delComment(data) {
  return Request('post', '/apg/comment/remove', data)
}

// 【审批评论】图片上传
export function upload(data) {
  return Request('post', '/apg/file/uploadBase64', data)
}

// 【审批评论】获取用户列表
export function getUserList(data) {
  return Request('post', '/apg/oa/approve/process/findEmpListNew', data)
}

// 【合同签约】查询是否有合同附件
export function getContractFileFlag(data) {
  return Request('get', '/apg/finance/contract/downLoad/additional?contractAttachType=2&contractId=' + data.contractId)
}
// 【合同签约】查询是否有合同附件
export function getWuyeFileFlag(data) {
  return Request('get', '/apg/finance/contract/downLoad/additional?contractAttachType=6&contractId=' + data.contractId)
}

// 【合同签约】获取合同退租附件图片
export function getContractFileImg(data) {
  return Request('get', '/apg/finance/contract/downLoad/additionalAndTerminate?contractAttachType=4&contractId=' + data.contractId)
}

// 【抬头变更】获取抬头变更附件
export function getHeadChangeFile(data) {
  return Request('get', '/apg/finance/contract/downLoad/newAdditionalHeader?contractId=' + data.contractId + '&contractAttachType=5')
}

// 【账单延期】获取账单延期附件
export function getBillDelayFile(data) {
  return Request('post', '/apg/bill/newBillDelayFile', data)
}

// 【账单拆分】获取账单拆分附件
export function getBillSplitFile(data) {
  return Request('post', '/apg/billSplit/billSplitFile', data)
}

// 【积分赠送】获取积分赠送附件
export function getBpOrderFile(data) {
  return Request('post', '/apg/enterprise/integral/newBpOrderFile', data)
}

// 【工单审批】获取工单审批附件
export function getCheckFiles(data) {
  return Request('post', '/apg/workForm/getCheckFiles', data)
}

// [关联单详情]
export function getFormWorkInfo(data) {
  return Request('post', '/apg/workForm/readForm', data)
}

//获取审批列表
export function getApproveList(data) {
  return Request('post', '/apd/oa/approve/myApproveTasks', data)
}

// 获取审批附件
export function getFiles(data) {
  return Request('post', '/apg/newFile/fileList', data)
}

// 获取指定设计师列表
export function getHandleEmpUser(data) {
  return Request('post', '/apg/workSheet/instance/getHandleEmpUser', data)
}

//  获取指定设计单位
export function getManageList(data) {
  return Request('post', '/apg/workSheet/manage/list', data)
}

//  保存办理信息
export function getDealAction(data) {
  return Request('post', '/apd/oa/approve/dealAction', data)
}

//  回显办理信息
export function getDealActionVo(data) {
  return Request('post', '/apd/oa/approve/dealActionVo', data)
}

//  办理回退
export function getReBack(data) {
  return Request('post', '/apd/oa/approve/goBackToThePreviousStep', data)
}

//  办理完成
export function getOver(data) {
  return Request('post', '/apd/oa/approve/submit', data)
}

//  合同签署详情
export function getDealActionVoFind(data) {
  return Request('post', '/apg//workContract/find', data)
}
