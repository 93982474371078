<template>
  <div id="title">
    <van-nav-bar :title="title"></van-nav-bar>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss">
@import "~@/assets/styles/theme";

#title {
  .van-nav-bar {
    background: $theme-color;
  }

  .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #fff !important;
    font-size: 0.18rem;
  }
}
</style>