<template>
  <div id="task">
    <Title title="审批列表"></Title>
    <!-- 审批类型选中 -->
    <ul class="type-list van-hairline--bottom">
      <li class="type-list-item" v-for="item in taskTypeList" :key="item.type" @click="onTaskType(item.type)">
        <van-badge :content="taskCounts[item.type]">
          <van-image width="24" height="24" :src="item.imgSrc" />
        </van-badge>
        <span :class="params.taskType == item.type && 'theme-bg'">{{
          item.title
        }}</span>
      </li>
    </ul>
    <!-- 审批状态选择 -->
    <van-dropdown-menu class="van-hairline--bottom">
      <van-dropdown-item v-model="params.processType" :options="approveTypeOpt" @change="onApproveType" />
      <van-dropdown-item v-model="params.approveStatus" :options="approveStatusOpt" @change="onApproveStatus" />
      <van-dropdown-item v-model="params.timeType" :options="timeTypeOpt" @change="onTimeType" />
    </van-dropdown-menu>
    <!-- 审批关键词搜索 -->
    <van-search v-model="params.kw" show-action placeholder="请输入搜索关键词" @search="onSearch">
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <main>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <section class="block-item" v-for="item in list" :key="item.approveId">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>{{ item.processName }}</div>
            <div class="title-right">
              <div class="title-right theme-color" @click="linkTo(item.processType, item.approveId, item.clueId)">
                查看
              </div>
            </div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">审批类型：</span><span>{{ approveTypeObj[item.processType] }}</span>
            </p>
            <p>
              <span class="info-color">发起时间：</span><span>{{ formatterDate(item.applyTime) }}</span>
            </p>
            <p>
              <span class="info-color">发&nbsp;&nbsp;起&nbsp;&nbsp;人：</span>
              {{ item.applyUserName }}
            </p>
            <template v-if="item.approveFormValueDtoList">
              <p v-for="i in item.approveFormValueDtoList">
                <span class="info-color">{{ i.formItemName }}：</span><span>{{ i.formItemValue }}</span>
              </p>
            </template>
            <p>
              <span class="info-color">审批状态：</span><span v-html="approveStatusObj[item.approveStatus]"></span>
            </p>
          </div>
        </section>
      </van-list>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Title from "@/components/title";
import {
  taskTypeList,
  timeTypeOpt,
  approveTypeOpt,
  approveStatusOpt,
  approveTypeObj,
  approveStatusObj,
} from "@/db/task";
import { getTaskCount, getMyTaskList } from "@/api/task/task";
import { formatterDate } from "@/utils/utils";
import { getUserInfo } from "@/api/mine/mine";
export default {
  components: { Footer, Title },
  data() {
    return {
      formatterDate,
      taskTypeList,
      timeTypeOpt,
      approveTypeOpt,
      approveStatusOpt,
      approveTypeObj,
      approveStatusObj,
      headImgSrc: require("../../assets/images/myself/head.png"),
      taskCounts: {},
      params: {
        kw: "",
        pageNo: 0,
        pageSize: 10,
        // 变更条件
        timeType: 1, // 时间段，1本月，2本年，3全部
        taskType: 1, // 头部类型：1待我审批，2我已审批，3我发起的，4抄送我的
        processType: "", //表格筛选审批类型
        approveStatus: "", //表格筛选审批状态
      },
      list: [],
      loading: false,
      finished: false, //是否结束加载，切换状态后记得开启
      mobile: "",
      fullName: "",
      spId: "",
    };
  },
  created() {
    this.getTaskCount();
    this.getUserInfo();
  },
  methods: {
    // 【请求】获取审批数量
    getTaskCount() {
      let data = this.params;
      getTaskCount(data).then((res) => {
        if (res.data) {
          this.taskCounts = {
            1: res.data.myExecutionCnt,
            2: res.data.myApproveCnt,
            3: res.data.myStartCnt,
            4: res.data.myCcCnt,
          };
        }
      });
    },

    // 【请求】获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.mobile = res.data.mobile;
        this.fullName = res.data.empInfo.fullName;
        this.spId = res.data.spInfo.spId;
      });
    },

    // 【请求】审批列表
    getMyTaskList() {
      let data = this.params;
      getMyTaskList(data).then((res) => {
        if (res.data) {
          this.list = [...this.list, ...res.data.content];
          this.loading = false;
          if (this.list.length >= res.data.total) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 【请求】下拉加载
    onLoad() {
      this.params.pageNo++;
      this.getMyTaskList();
    },

    // 状态变更重置
    reset() {
      this.list = [];
      this.params.pageNo = 1;
      this.finished = false;
      this.getMyTaskList();
    },

    // 【监听】审批时段
    onTimeType() {
      this.getTaskCount();
    },

    // 【监听】审批类型选择
    onTaskType(type) {
      this.params.taskType = type;
      this.reset();
    },

    // 【监听】审批类型选择
    onApproveType() {
      this.getTaskCount();
      this.reset();
    },

    // 【监听】审批状态选择
    onApproveStatus() {
      this.getTaskCount();
      this.reset();
    },

    // 【监听】搜索
    onSearch() {
      this.getTaskCount();
      this.reset();
    },

    // 【监听】跳转审批详情
    linkTo(processType, approveId, clueId) {
      this.$router.push({
        path: "/task/taskInfo",
        query: { processType, approveId, clueId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";

#task {
  display: flex;
  flex-direction: column;
  height: 100%;

  main {
    flex: 1;
    overflow: auto;
    padding: 0 .1rem;
    background: $bg-color;
  }

  .type-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 0.8rem;
  }

  .type-list-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >span {
      margin-top: 0.05rem;
      font-size: 0.14rem;
      padding: 0 0.05rem;
      border-radius: 0.1rem;
    }
  }

  .theme-bg {
    background: $theme-color;
    color: #fff;
  }

  // 大盒子边距
  .van-list {
    box-sizing: border-box;
  }

  // 文本模块
  .block-item {
    border-top: $bg-color 0.15rem solid;
    display: flex;
    flex-direction: column;
    padding: 0 0.16rem 0.16rem 0.16rem;
    box-sizing: border-box;
    background: #fff;

    >.block-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.1rem 0;
      box-sizing: border-box;

      .title-left {
        display: flex;
        align-items: center;

        >i {
          display: inline-block;
          background: $theme-color;
          width: 0.04rem;
          height: 0.15rem;
          border-radius: 0 0.04rem 0.04rem 0;
          margin-right: 0.1rem;
        }
      }
    }

    // 内容区上边距
    .block-content {
      margin-top: 0.1rem;

      >p {
        display: flex;
        align-items: center;
        padding: 0.02rem 0;
        font-size: .14rem;
      }

      .info-color {
        display: flex;
        align-items: center;

        .van-image {
          margin-right: 0.05rem;
        }
      }
    }
  }
}
</style>

<style>
#task .van-dropdown-menu__bar {
  box-shadow: none !important;
}
</style>
