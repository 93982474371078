// 审批类型
export const taskTypeList = [{
  title: "待我审批",
  imgSrc: require("../assets/images/task/taskType/daiwo.png"),
  type: 1
},
{
  title: "我已审批",
  imgSrc: require("../assets/images/task/taskType/woyi.png"),
  type: 2
},
{
  title: "我发起的",
  imgSrc: require("../assets/images/task/taskType/wofaqi.png"),
  type: 3
},
{
  title: "抄送我的",
  imgSrc: require("../assets/images/task/taskType/caosong.png"),
  type: 4
},
]

// 审批时段
export const timeTypeOpt = [{
  value: 1,
  text: '本月',
}, {
  value: 2,
  text: '本年',
}, {
  value: 3,
  text: '全部',
}]


// 审批类型
export const approveTypeOpt = [{
  value: '',
  text: '全部类型',
}, {
  value: '1',
  text: '合同签约',
}, {
  value: '3',
  text: '合同退租',
}, {
  value: '4',
  text: '合同作废',
},
{
  value: '5',
  text: '工单签发',
},
{
  value: '7',
  text: '账单延期',
},
{
  value: '12',
  text: '工单验收',
},
{
  value: '14',
  text: '抬头变更',
},
{
  value: '15',
  text: '积分赠送',
},
{
  value: '16',
  text: '积分补扣',
},
{
  value: '17',
  text: '预付费扣款',
},
{
  value: '18',
  text: '预付费补偿',
},
{
  value: '30',
  text: '部门工作联系单',
},
{
  value: '31',
  text: '维修改造工程申请',
},
{
  value: '32',
  text: '工单延期申请',
},
{
  value: '33',
  text: '账单拆分',
},
{
  value: '58',
  text: '房源变更',
},
{
  value: '59',
  text: '房源拆合',
},
{
  value: '60',
  text: '续签转退租',
},
{
  value: '70',
  text: '账单撤回',
},
{
  value: '80',
  text: '预定签约',
},
{
  value: '81',
  text: '预定作废',
},
{
  value: '90',
  text: '工程相关审批',
},
{
  value: '99',
  text: '房源批量定价',
},
]
export const approveTypeObj = {
  1: "合同签约",
  3: "合同退租",
  4: "合同作废",
  5: "工单签发",
  7: "账单延期",
  12: "工单验收",
  14: "抬头变更",
  15: "积分赠送",
  16: "积分补扣",
  17: "预付费扣款",
  18: "预付费补偿",
  30: "工程预算联系单",
  31: "工程改造工单申请",
  32: "工单延期申请",
  33: "账单拆分",
  58: "房源变更",
  59: "房源拆合",
  60: "续签转退租",
  70: "账单撤回",
  80: "预定签约",
  81: "预定作废",
  90: "工程相关审批",
  99: "房源批量定价",
}


// 审批状态
export const approveStatusOpt = [{
  value: '',
  text: '全部状态',
}, {
  value: '0',
  text: '待审批',
}, {
  value: '1',
  text: '审批通过',
}, {
  value: '2',
  text: '审批撤回',
}, {
  value: '3',
  text: '办理中',
}, {
  value: '-1',
  text: '审批拒绝',
}]


export const approveStatusObj = {
  0: `<span class="warning-tag">待审批</span>`,
  1: `<span class="success-tag">审批通过</span>`,
  2: `<span class="theme-tag">审批撤回</span>`,
  3: `<span>办理中</span>`,
  "-1": `<span class="danger-tag">审批拒绝</span>`,
}


export const approveStatusPngObj = {
  0: require("../assets/images/approval.png"),
  1: require("../assets/images/adopt.png"),
  2: require("../assets/images/revoke.png"),
  3: require("../assets/images/transact.png"),
  "-1": require("../assets/images/refuse.png"),
}